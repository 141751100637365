import DVCore from "./core/DVCore";

const AuthAPI = {};

AuthAPI.baseURL = "/api/auth/";

/**
 * Login function
 * @param params - object of username, password
 * @param {*} onSuccess - callback
 * @param {*} onFail - callback
 * @param {*} onError - callback (Optional)
 */
AuthAPI.login = (params, onSuccess, onFail, onError = null) => {
    //console.log(AuthAPI);
    let url = AuthAPI.baseURL + "json";
    return DVCore.post(url, params, onSuccess, onFail, onError);
};

/**
 * Logout
 * @param {*} onSuccess - callback
 * @param {*} onFail - callback
 * @param {*} onError - callback (Optional)
 */
AuthAPI.logout = (onSuccess, onFail, onError = null) => {
    let url = AuthAPI.baseURL + "logout";
    return DVCore.post(url, null, onSuccess, onFail, onError);
};


export default AuthAPI;