import DVCore, { BaseAPI } from "./core/DVCore";

const UsersAPI = BaseAPI("/api/users/");

/**
 * Get active user information
 * @param {*} onUser - callback
 * @param {*} onNoUser - callback
 * @param {*} onError -callback (Optional)
 */
UsersAPI.me = (onUser, onNoUser, onError = null) => {
    let url = UsersAPI.baseURL + "me";
    return DVCore.get(url, null, onUser, onNoUser, onError);
};

export default UsersAPI;