import { Avatar, Button, Card, List, Typography } from "antd";
import { CloseCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

export default function Console(props) {

    const { user, sessions, onLogout } = props;

    return <>
        <Card
            title="User">
            <div style={{ verticalAlign: 'top', display: "inline-block" }}><Avatar src="student.png" size={120} /></div>
            <div style={{ display: "inline-block" }}>
                &nbsp;
                <br />
                Username  <Text strong>{user?.username}</Text>
                <br />
                Name (english) <Text strong>{user?.firstname} {user?.lastname}</Text>
                <br />
                Namt (thai) <Text strong>{user?.firstnameTH} {user?.lastnameTH}</Text>
            </div>
            <div></div>
            <div style={{ float: 'right' }}><Button onClick={onLogout}>Logout</Button></div>
        </Card>
        <List header="Tickets/Sessions (last 90 days)"
            dataSource={sessions}
            renderItem={(item, index) => (
                <List.Item>
                    <List.Item.Meta
                        avatar={<Avatar src={item?.app_icon} />}
                        title={item?.app_name}
                        description={<>
                            Client Access: {item?.create_time} @ {item?.client_ip}
                            <br />
                            Service: {item?.service_url}
                            <br />
                            {item?.service_ip ? <>
                                Service Access: {item?.expire_time}  @ {item?.service_ip}
                            </> : <>
                                Valid until: {item?.expire_time}
                            </>}
                        </>}
                    />
                </List.Item >
            )
            }
        />

    </>
}