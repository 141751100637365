/**
 * DVCore
 * History
 * July 16, 2021 - add BaseAPI, Krerk Piromsopa, Ph.D.
 */

const DVCore = {};

DVCore.debugMode = false;

/**
 * Base method for fetch API
 * @param {String} method GET/POST/PUT/DELETE
 * @param {String} url
 * @param {callback} bodyData
 * @param {callback} onSuccess
 * @param {callback} onFail
 * @param {callback}} onError - error callback
 */
DVCore.fetch = (method, url, bodyData, onSuccess, onFail, onError = null, contentType = 'json', headers = null) => {

    const handleError = (e) => {
        if (onError === null) {
            if (onFail) {
                onFail(e);
            } else {
                DVCore.debugMode && console.log('DVCore.fetch - handlerError', e);
                console.log('undefined onFail in DVCore.fetch');
            }
        } else {
            console.log(onError);
            onError();
        }
    }

    DVCore.debugMode && console.log('DVCore.fetch', method, url, bodyData);

    // assume json
    let m_headers = {
        'Content-Type': 'application/json'
    }

    if (headers !== null) {
        m_headers = headers;
    }

    fetch(url, {
        method: method,
        credentials: "include",
        headers: m_headers,
        body: bodyData,
    })
        .then((response) => {
            DVCore.debugMode && console.log('DVCore.fetch - response', response);
            if (response.ok) {
                if (contentType != 'json') {
                    response.text()
                        .then((text) => {
                            DVCore.debugMode && console.log('DVCore.fetch - text', text);
                            onSuccess(text);
                        })
                        .catch((err) => {
                            DVCore.debugMode && console.log('DVCore.fetch - text error', err);
                            handleError(err);
                        });

                } else {
                    response.json()
                        .then((jsonDat) => {
                            DVCore.debugMode && console.log('DVCore.fetch - jsonDat', jsonDat);
                            onSuccess(jsonDat);
                        })
                        .catch((err) => {
                            DVCore.debugMode && console.log('DVCore.fetch - JSON error', err);
                            handleError(err);
                        });
                }
            } else {
                if (contentType != 'json') {
                    response.text()
                        .then((text) => {
                            DVCore.debugMode && console.log('DVCore.fetch - text', text);
                            onFail(text);
                        })
                        .catch((err) => {
                            DVCore.debugMode && console.log('DVCore.fetch - text error', err);
                            handleError(err);
                        });
                } else {
                    response.json()
                        .then((jsonDat) => {
                            DVCore.debugMode && console.log('DVCore.fetch - jsonDat', jsonDat);
                            onFail(jsonDat);
                        })
                        .catch((err) => {
                            DVCore.debugMode && console.log('DVCore.fetch - JSON error', err);
                            handleError(err);
                        });
                }
            }
        })
        .catch((err) => {
            DVCore.debugMode && console.log('DVCore.fetch - error', err);
            handleError(err);
        });
};

/**
 * Convert object to FormData
 * @param {*} params - objects/dict
 * @returns {FormData}
 */
DVCore.objToFormData = (params) => {
    let formData = new FormData();
    for (var key in params) {
        //console.log(key);
        formData.append(key, params[key]);
    }
    return formData;
};

/**
 * POST objects as FormData
 * @param {String} url
 * @param {*} params
 * @param {*} onSuccess - callback
 * @param {*} onFail - callback
 * @param {*} onError - callback
 */
DVCore.postForm = (url, params, onSuccess, onFail, onError, contentType = 'json', headers = 'application/x-www-form-urlencoded') => {
    let formData = DVCore.objToFormData(params);
    DVCore.postRaw(url, formData, onSuccess, onFail, onError, contentType, headers);
};

/**
 * POST json data, return json
 * @param {String} url
 * @param {*} data
 * @param {*} onSuccess
 * @param {*} onFail
 * @param {*} onError
 */
DVCore.post = (url, data, onSuccess, onFail, onError) => {
    let jsonData = JSON.stringify(data);
    return DVCore.postRaw(url, jsonData, onSuccess, onFail, onError);
};

/**
 * POST raw body data, return JSON
 * @param {String} url
 * @param {*} bodyData
 * @param {*} onSuccess - callback
 * @param {*} onFail - callback
 * @param {*} onError - callback
 */
DVCore.postRaw = (url, bodyData, onSuccess, onFail, onError, contentType = "json") => {
    return DVCore.fetch("POST", url, bodyData, onSuccess, onFail, onError, contentType);
};

/**
 * PUT json data, return JSON
 * @param {String} url
 * @param {*} data
 * @param {*} onSuccess
 * @param {*} onFail
 * @param {*} onError
 */
DVCore.put = (url, data, onSuccess, onFail, onError) => {
    let jsonData = JSON.stringify(data);
    return DVCore.putRaw(url, jsonData, onSuccess, onFail, onError);
};

/**
 * PUT raw body data, return JSON
 * @param {String} url
 * @param {*} bodyData
 * @param {*} onSuccess
 * @param {*} onFail
 * @param {*} onError
 */
DVCore.putRaw = (url, bodyData, onSuccess, onFail, onError) => {
    return DVCore.fetch("PUT", url, bodyData, onSuccess, onFail, onError);
};

/**
 * Get, return JSON
 * @param {String} url
 * @param {*} onSuccess - callback
 * @param {*} onFail - callback
 * @param {*} onError - callback
 */
DVCore.get = (url, params, onSuccess, onFail, onError) => {
    let mURL = url;
    if (params !== null) {
        mURL += "?" + new URLSearchParams(params);
    }
    return DVCore.fetch("GET", mURL, null, onSuccess, onFail, onError);
};

/**
 * Get, return raw data
 * @param {String} url
 * @param {*} onSuccess - callback
 * @param {*} onFail - callback
 * @param {*} onError - callback
 */
DVCore.getRaw = (url, params, onSuccess, onFail, onError) => {
    let mURL = url;
    if (params !== null) {
        mURL += "?" + new URLSearchParams(params);
    }
    return DVCore.fetch("GET", mURL, null, onSuccess, onFail, onError, "html");
};

/**
 * Delete object at URL, return JSON containing the number of objects
 * deleted.
 *
 * @param {String} url
 * @param {callback} onSuccess(data:string) - callback
 * @param {callback} onFail(err) - callback
 * @param {callback} onError - callback
 */
DVCore.delete = (url, onSuccess, onFail, onError) => {
    return DVCore.fetch("DELETE", url, null, onSuccess, onFail, onError);
};

/**
 * Export file at URL.
 *
 * @param {String} url
 * @param {callback} onSuccess - callback
 * @param {callback} onFail - callback
 * @param {callback} onError - callback
 */
DVCore.exportFile = (url, params, onSuccess, onFail, onError = null) => {
    let mURL = url;
    let ftype = '';
    let filename = `GetA-exported-file.${ftype}`;
    if (params !== null) {
        mURL += "?" + new URLSearchParams(params);
    }

    const handleError = (e) => {
        if (onError === null) {
            if (onFail) {
                onFail(e);
            } else {
                DVCore.debugMode && console.log('DVCore.fetch - handlerError', e);
                console.log('undefined onFail in DVCore.fetch');
            }
        } else {
            console.log(onError);
            onError();
        }
    }

    const options = {
        headers: {
            Authorization: "Deverhood"
        }
    }

    fetch(mURL, options)
        .then( res => {
            if (res.status == 200){
                if (res.headers.get('Content-Type').includes('sheet'))
                    ftype = 'xlsx';
                if (res.headers.get('Content-Type').includes('pdf'))
                    ftype = 'pdf'

                if (res.headers.has('filename'))
                    filename = res.headers.get('filename');
                else {
                    filename = res.headers.get('content-disposition')
                                          .split(';')
                                          .find(n => n.includes("filename*=utf-8''"))
                                          .replace("filename*=utf-8''", '')
                                          .trim();
                }
                filename = decodeURIComponent(filename);
                return res.blob()
            }
            else
                handleError(res.statusText);
        })
        .then( blob => {
            // download file
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename; 
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            onSuccess();
        })
        .catch((err) => {
            DVCore.debugMode && console.log('DVCore.fetch - error', err);
            handleError(err);
        });
};

export const BaseAPI = (baseURL) => {
    const api = {};

    api.baseURL = baseURL;

    api.search = (params, onSuccess, onFail, onError = null) => {
        let url = api.baseURL;
        return DVCore.get(url, params, onSuccess, onFail, onError);
    }

    api.options = (parms, onSuccess, onFail, onError = null) => {
        let url = api.baseURL + 'options';
        return DVCore.get(url, parms, onSuccess, onFail, onError);
    }

    api.get = (id, onSuccess, onFail, onError = null) => {
        let url = api.baseURL + id;
        return DVCore.get(url, {}, onSuccess, onFail, onError);
    }

    api.insert = (data, onSuccess, onFail, onError = null) => {
        let url = api.baseURL;
        return DVCore.post(url, data, onSuccess, onFail, onError);
    }

    api.update = (id, data, onSuccess, onFail, onError = null) => {
        let url = api.baseURL + id;
        return DVCore.put(url, data, onSuccess, onFail, onError);
    }

    api.upsert = (id, data, onSuccess, onFail, onError = null) => {
        let url = api.baseURL;
        console.log('upsert.id', id);
        if (id == '' || id == null || id == '_new') {
            return DVCore.post(url, data, onSuccess, onFail, onError);
        } else {
            url += id;
            return DVCore.put(url, data, onSuccess, onFail, onError);
        }

    }

    api.delete = (id, onSuccess, onFail, onError = null) => {
        let url = api.baseURL + id;
        return DVCore.delete(url, onSuccess, onFail, onError);
    }

    return api;
}

export default DVCore;
