import { Button, Card, Typography } from "antd";
import TicketsAPI from "../api/ticket";
import { useRef } from "react";

const { Paragraph, Text } = Typography;

export default function Authorize(props) {
    const { service } = props;
    const frmRef = useRef();

    const grantAccess = () => {
        console.log('grantAccess');
        const frm=frmRef.current;
        frm.submit();
    }

    console.log('service', service);

    return <Card title={"Authorization"}>
        <Paragraph>
            Application <Text strong>{service?.Name} - {service?.URL}</Text> is requesting for your credential.
        </Paragraph>
        <div style={{ float: 'right' }}>
            <form action="/api/tickets/grantTicket" method="post" ref={frmRef}></form>
            <Button type="primary" onClick={grantAccess}>Grant</Button>
        </div>
    </Card>
}